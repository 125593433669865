import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_ROOT_API

class UserService {
  async getProfile() {
    const response = await axios.get(API_URL + '/users/profile', { headers: authHeader() })
    return response.data
  }

  async updateProfile(element) {
    return await axios.post(API_URL + '/users/profile', element, { headers: authHeader() })
  }

  async updateBank(element) {
    return await axios.post(API_URL + '/users/bank', element, { headers: authHeader() })
  }

  async getBanktype() {
    const response = await axios.get(API_URL + '/users/banktype', { headers: authHeader() })
    return response.data
  }

  async getHistory() {
    const response = await axios.get(API_URL + '/orders', { headers: authHeader() })
    return response.data
  }

  async getDateLotto() {
    const response = await axios.get(API_URL + '/lottos/getdate', { headers: authHeader() })
    return response.data
  }

  async getOrderById(element) {
    const response = await axios.get(API_URL + '/orders/detail/' + element, { headers: authHeader() })
    return response.data
  }
}

export default new UserService()
