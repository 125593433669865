<template>
  <div class="data" v-if="noProfile">
    <div class="content-hea">
      <h1>ข้อมูลส่วนตัว</h1>
    </div>

    <div class="hong-card-content">
      <Form @submit="updateProfile">
        <div class="form-data">
          <div class="row mb-3">
            <label for="fname" class="col-sm-2 col-form-label">ชื่อ</label>
            <div class="col-sm-10">
              <Field type="text" class="form-control" name="name" />
            </div>
          </div>
          <div class="row mb-3">
            <label for="lname" class="col-sm-2 col-form-label">นามสกุล</label>
            <div class="col-sm-10">
              <Field type="text" class="form-control" name="lastname" />
            </div>
          </div>
          <div class="row mb-3">
            <label for="tel" class="col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
            <div class="col-sm-10">
              <Field type="text" class="form-control" name="phone" />
            </div>
          </div>
          <hr />
          <br />
          <div class="contact-group">
            <div class="col-md-10">
              <div class="row mb-3">
                <label for="facebook" class="col-sm-4 col-form-label">เฟซบุ๊ค</label>
                <div class="col-sm-8">
                  <Field type="text" class="form-control contact" name="facebook" />
                </div>
              </div>
            </div>
            <div class="col-md-10">
              <div class="row mb-4">
                <label for="line" class="col-sm-4 col-form-label">ไลน์</label>
                <div class="col-sm-8">
                  <Field type="text" class="form-control contact" name="line" />
                </div>
              </div>
            </div>
          </div>
          <!-- <button class="btn" v-on:click="updateProfiles">แก้ไขข้อมูล</button> -->
          <button class="btn" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>แก้ไขข้อมูล</span>
          </button>
        </div>
      </Form>
    </div>
  </div>
  <div class="data flex-grow" v-if="dataReady">
    <h1>ข้อมูลส่วนตัว</h1>
    <Form @submit="updateProfile">
      <div class="form-data">
        <div class="row mb-3">
          <label for="fname" class="col-sm-2 col-form-label">ชื่อ</label>
          <div class="col-sm-10">
            <Field type="text" class="form-control" name="name" :value="content.name" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="lname" class="col-sm-2 col-form-label">นามสกุล</label>
          <div class="col-sm-10">
            <Field type="text" class="form-control" name="lastname" :value="content.lastname" />
          </div>
        </div>
        <div class="row mb-3">
          <label for="tel" class="col-sm-2 col-form-label">เบอร์โทรศัพท์</label>
          <div class="col-sm-10">
            <Field type="text" class="form-control" name="phone" :value="content.phone" />
          </div>
        </div>
        <hr />
        <br />
        <div class="contact-group">
          <div class="col-md-10">
            <div class="row mb-3">
              <label for="facebook" class="col-sm-4 col-form-label">เฟซบุ๊ค</label>
              <div class="col-sm-8">
                <Field type="text" class="form-control contact" name="facebook" :value="content.facebook" />
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="row mb-4">
              <label for="line" class="col-sm-4 col-form-label">ไลน์</label>
              <div class="col-sm-8">
                <Field type="text" class="form-control contact" name="line" :value="content.line" />
              </div>
            </div>
          </div>
        </div>
        <!-- <button class="btn" v-on:click="updateProfiles">แก้ไขข้อมูล</button> -->
        <button class="btn" :disabled="loading">
          <span v-show="loading" class="spinner-border spinner-border-sm"></span>
          <span>แก้ไขข้อมูล</span>
        </button>
      </div>
    </Form>
  </div>
</template>
<script>
import UserService from '../services/user.service'
import { Form, Field } from 'vee-validate'

export default {
  name: 'Profile',
  components: {
    Form,
    Field
  },
  data() {
    return {
      dataReady: false,
      loading: false,
      noProfile: false,
      content: {}
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    }
  },
  methods: {
    async updateProfile(eleForm) {
      this.loading = true
      const response = await UserService.updateProfile(eleForm)
      this.loading = false
      this.$toast.success(response.data.message)
      const responses = await UserService.getProfile()
      this.content = responses.user.user_profile
    }
  },
  async created() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
    try {
      const response = await UserService.getProfile()
      if (response.user.user_profile != null) {
        this.content = response.user.user_profile
        this.dataReady = true
      } else {
        this.noProfile = true
      }
    } catch (error) {
      this.content = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    }
  }
}
</script>
